<template>
	<section class="settings">
		<h1 class="is-size-1" v-translate>Settings</h1>
		<language-select></language-select>
		<b-field :label="$gettext('Mobile phone')" :message="phoneError" :type="{ 'is-danger': phoneError }">
			<b-input v-model="phone" name="phone" type="phone" icon="cellphone" required @input="checkPhone(phone)" @blur="checkPhone(phone)"></b-input>
		</b-field>
		<b-field :label="$gettext('Two factor authentication')" :message="$gettext('Requires a valid mobile phone number')">
			<b-switch v-model="has2FAEnabled" @change.native="toggle2FA">
				{{ has2FAEnabled ? $gettext('Enabled') : $gettext('Disabled') }}
			</b-switch>
		</b-field>
	</section>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { checkPhone, errorToast } from '@/mixins';
import LanguageSelect from '@/components/LanguageSelect';

export default {
	components: { LanguageSelect },
	data() {
		return {
			phone: '',
			phoneError: '',
			has2FAEnabled: false,
		};
	},
	computed: {
		...mapState(['user']),
	},
	mixins: [checkPhone, errorToast],
	methods: {
		...mapActions(['patchUser']),
		setData() {
			if (this.user && this.user.profile.phone) this.phone = this.user.profile.phone;
			if (this.user && this.user.profile.twoFactorAuth) this.has2FAEnabled = this.user.profile.twoFactorAuth.has2FAEnabled;
		},
		toggle2FA() {
			if (this.checkPhone(this.phone)) {
				const params = {
					'profile.phone': this.phone,
					'profile.twoFactorAuth.has2FAEnabled': this.has2FAEnabled,
				};
				this.patchUser({ id: this.user._id, ...params })
					.then(this.setData)
					.catch(this.errorToast);
			}
		},
	},
	mounted() {
		this.setData();
	},
};
</script>
